import styled from "styled-components";

export const Container = styled.div`
	width: 100vw;
	padding: 50px 0%;
	h5 {
		margin: 30px !important;
	}
	@media screen and (max-width: 600px) {
		h5 {
			text-align: left;
			padding-left: 50px;
		}
	}
`;

export const Rounded = styled.div`
	width: 200px !important;

	height: 200px;
	border-radius: 100%;
	box-shadow: -2px 6px 10px -1px rgba(212, 212, 212, 0.75);
	-webkit-box-shadow: -2px 6px 10px -1px rgba(212, 212, 212, 0.75);
	-moz-box-shadow: -2px 6px 10px -1px rgba(212, 212, 212, 0.75);
	padding: 50px 40px;
	@media screen and (max-width: 600px) {
		margin-left: 40px;
	}
`;
