import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	overflow-x: hidden;
	padding: 0 70px;
	padding-top: 20px;
	margin: 0;

	.top-100 {
		margin-top: 100px;
	}

	.contact {
		margin-bottom: 20px;
		height: 42%;
		margin-left: 50% !important;
		transform: translateX(-50%);
		max-height: 200px;
		max-width: 200px;
		height: 170px;
		width: 170px;
		object-fit: cover;
		border-radius: 100%;
		border: 2px solid black;
	}
	.title {
		font-size: 50px;
		font-weight: 700;
		margin: 50px auto;
		margin-bottom: 30px;
		width: fit-content;
	}
	.two-by-two {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
		padding: 0;
		margin: 0;
		margin-top: 20px;
	}
	.mobile {
		display: none;
	}
	.submmit {
		width: 50%;
		margin: 20px auto;
		margin-left: 50%;
		transform: translateX(-50%);
	}

	.login-vector {
		max-width: 100%;
		margin-top: 50%;
		transform: translateY(-50%);
	}
	.form {
		margin-top: 50%;
		transform: translateY(-50%);
	}
	.form-card {
		padding: 50px;
	}
	.height-50 {
		max-width: 100%;
		margin: 0 auto;
	}
	h2 {
		margin-bottom: 50px;
	}
	@media screen and (max-width: 600px) {
		padding-top: 10px;
		height: fit-content !important;
		padding: 10px 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.desktop {
			display: none;
		}
		.mobile {
			display: flex;
			flex-direction: column;
			padding: 15px;
		}
		.form-card {
			padding: 0px;
		}
		.top-100 {
			margin-top: 10px;
		}
		.two-by-two {
			grid-template-columns: 1fr;

			margin-top: 50px;
		}

		.login-vector {
			display: none;
		}
		.title {
			font-size: 30px;
			font-weight: 300;
			margin: 0px auto;
			margin-bottom: 0px;
			width: fit-content;
		}
		.col {
			height: fit-content;
		}
	}
`;
