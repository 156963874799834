import {
	Card as CustomCard,
	Container,
	FilterContainer,
	Stats,
} from "./home.styles";

import { useState } from "react";
import { BsArrowDown, BsArrowRight, BsClock } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Card } from "shards-react";
import AvailableDoctors from "../../components/Available-doctors/Available-doctors.component";
import CustomSlider from "../../components/Slider/slider.component";
import Select from "react-select";
import { searchParamsChanged } from "../../store/search-reducer";
import {
	formatDepartment,
	formatLanguage,
	formatLocation,
} from "../../utils/formatResponse";
const Home = () => {
	const departments = formatDepartment(
		useSelector((state) => state.departments),
		true
	);
	const languages = formatLanguage(useSelector((state) => state.language));
	const locations = formatLocation(useSelector((state) => state.location));

	const [searchParams, setSearchParams] = useState({
		department: "",
		language: "",
		gender: "",
		location: "",
	});

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const onSearch = () => {
		dispatch(searchParamsChanged(searchParams));
		navigate("/doctors");
	};
	const handleChange = (e) => {
		if (e.name === "gender")
			setSearchParams({ ...searchParams, gender: e.value });
		else if (e.name === "department")
			setSearchParams({ ...searchParams, department: e.value });
		else if (e.name === "language")
			setSearchParams({ ...searchParams, language: e.value });
		else if (e.name === "location")
			setSearchParams({ ...searchParams, location: e.value });
	};
	return (
		<Container>
			<div className='search'>
				<FilterContainer>
					<CustomCard>
						<h1 className='bold-title'>
							Search a doctor,
							<span className='orange'>
								<br /> Book an appointment online
							</span>
						</h1>
					</CustomCard>

					<div className='grid'>
						<Select
							options={[
								{ label: "Male", value: "male", name: "gender" },
								{ label: "Female", value: "female", name: "gender" },
							]}
							placeholder='Gender'
							onChange={handleChange}
						/>
						<Select
							options={departments}
							placeholder='Department'
							onChange={handleChange}
						/>
						<Select
							options={languages}
							placeholder='Language'
							onChange={handleChange}
						/>
						<Select
							options={locations}
							placeholder='Location'
							onChange={handleChange}
						/>
						<button className='find' onClick={onSearch}>
							Find
						</button>
					</div>
				</FilterContainer>
			</div>
			<Card className='form-card ml-5 mr-5 services'>
				<div className='column'>
					<h4>
						Select a <br /> doctor
					</h4>

					<p>
						We have a large selection of excellent doctors in our portfolio.
						Simply select your preferred doctor.
					</p>
				</div>
				<BsArrowRight className='right' />
				<BsArrowDown className='down' />
				<div className='column'>
					<h4>Create an account</h4>

					<p>
						In just a few steps, you can create an account with which you can
						also manage your appointments.
					</p>
				</div>
				<BsArrowRight className='right' />
				<BsArrowDown className='down' />

				<div className='column'>
					<h4>Book an appointment</h4>

					<p>
						You can see the doctors' availability and book an appointment
						quickly and easily.
					</p>
				</div>
				<BsArrowRight className='right' />
				<BsArrowDown className='down' />

				<div className='column'>
					<h4>See the doctor</h4>

					<p>
						Finally, you can see your chosen doctor. We wish you all the best.
					</p>
				</div>
			</Card>
			<Stats>
				<div className='column'>
					<BsClock />
					<h5>Get an appointment quickly</h5>
				</div>
				<div className='column'>
					<img src={require("../../Assets/heart.png")} alt='' />
					<h5>Meet excellent doctors</h5>
				</div>
				<div className='column'>
					<MdManageAccounts />
					<h5>Manage your appointments easily</h5>
				</div>
			</Stats>
			<h1>Adey Appointment - Benefits for your health</h1>
			<p className='desc' style={{ textAlign: "center!improtant" }}>
				It is often not so easy to find the right doctor. Adey Appointment
				offers you a wide range of doctors with different specialities. <br />{" "}
				This means that you can find the right doctor for your needs quickly and
				easily and book them directly. And this service is free of charge for
				you.
			</p>
			<CustomSlider />
			<AvailableDoctors />
		</Container>
	);
};

export default Home;
