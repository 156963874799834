import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Card, Col, FormInput, FormSelect, Row } from "shards-react";
import { Container } from "./Make-appointmets.styles";

import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import appointmentApi from "../../api/appointment";
import { formatAMPM } from "../../utils/formatAMPM";
import { formatDate } from "../../utils/formatDate";
import Featching from "../../components/Featching/loading.page";
import {
	formatDepartment,
	formatLanguage,
	formatLocation,
} from "../../utils/formatResponse";
import servicesApi from "../../api/services";

const MakeAppointmentAm = (props) => {
	const [reason, setReason] = useState("");
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState("male");
	const [age, setAge] = useState(0);
	const [address, setAddress] = useState("");
	const [phone, setPhone] = useState("");
	const location = useLocation();
	const [services, setServices] = useState([]);
	const [selectedService, setSelectedService] = useState("");
	const languages = formatLanguage(useSelector((state) => state.language));
	const locations = formatLocation(useSelector((state) => state.location));
	const departments = formatDepartment(
		useSelector((state) => state.departments),
		true
	);
	const [days, setDays] = useState([]);
	const [date, setDate] = useState(
		days && formatDate(new Date())
		// getting the next day
	);
	const [time, setTime] = useState([]);
	const [apptTime, setApptTime] = useState(time && time[0]);
	const user = useSelector((state) => state.user[0]);
	const params = useParams();
	const [fetch, setFetch] = useState();

	const navigate = useNavigate();
	const doctor = useSelector((state) =>
		state.doctors.find((doc) => doc._id === params.id)
	);

	const convertor = (date) => {
		var today = new Date(date);
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + "-" + mm + "-" + dd;
		return today;
	};
	const renderDayContents = (day, date) => {
		const tooltipText = `Tooltip for date: ${date}`;

		return (
			<div>
				{days.includes(convertor(date)) ? (
					<span title={tooltipText}>{new Date(date).getDate()}</span>
				) : (
					<span className='inactive' title={tooltipText}>
						{new Date(date).getDate()}
					</span>
				)}
			</div>
		);
	};

	useEffect(() => {
		appointmentApi.getTimeByDoctor(
			params.id,
			formatDate(new Date(date)),
			fetchWorkingTimeSuccess,
			onFaileure
		);
		servicesApi.getAll(onServiceSuccess, onFaileure);
		// eslint-disable-next-line
	}, [date, fetch]);

	const onServiceSuccess = (resp) => {
		setServices(resp.data.services);
	};

	useEffect(() => {
		appointmentApi.getDateByDoctor(
			params.id,
			fetchWorkingDateSuccess,
			(error) => console.log("error", error)
		);
		// eslint-disable-next-line
	}, [fetch]);

	const fetchWorkingDateSuccess = (resp) => {
		setDays(resp.data.availableDates);

		console.warn("here", resp.data);
		resp.data.availableDates.length &&
			setDate(new Date(resp.data.availableDates[0]));
	};
	const fetchWorkingTimeSuccess = (resp) => {
		setTime(resp.data.available_hours);
	};

	const makeAppointment = (e) => {
		e.preventDefault();

		let data = !sessionStorage.getItem("id")
			? {
					appointmentDate: date,
					appointmentTime: apptTime,
					department: doctor.department,
					doctor: params.id,
					patientFirstName: firstName,
					patientLastName: lastName,
					patientEmail: email,
					reasonForVisit: reason,
					patientPhone: phone,
					patientGender: gender,
					patientAddress: address,
					patientAge: age,
					service: selectedService,
					isWhiteList: true,
			  }
			: {
					patientId: sessionStorage.getItem("id"),
					appointmentDate: date,
					appointmentTime: apptTime,
					department: doctor.department,
					doctor: params.id,
					reasonForVisit: reason,
					service: selectedService,
					isWhiteList: true,
			  };
		toast.promise(appointmentApi.makeAppointment(data, onSuccess, onFaileure), {
			pending: "Making Appointment ...",
		});
	};

	const onFaileure = (error) => {
		setTime([]);
	};

	const onSuccess = (data) => {
		setTimeout(() => {
			if (user) {
				navigate("/appointments");
			} else {
				navigate("/doctors");
			}
		}, 3000);

		setFetch(!fetch);
	};

	return (
		<Container>
			<ToastContainer />{" "}
			{doctor ? (
				<Row>
					<Col lg={12} md={12}>
						<Card className='form-card  '>
							<div className='doc-list'>
								{doctor && doctor.profilePicture ? (
									<img src={doctor.profilePicture} alt='' className='doc-img' />
								) : (
									<img
										src={require("../../Assets/default.png")}
										alt=''
										className='doc-img'
									/>
								)}
								{doctor && (
									<div>
										<h5>
											{location.pathname.slice(-2) !== "am"
												? "Dr.  "
												: "ዶ / ር "}
											{doctor.firstName.en &&
												(location.pathname.slice(-2) === "am"
													? doctor.firstName.am
													: doctor.firstName.en)}
											{doctor.lastName.am &&
												(location.pathname.slice(-2) === "am"
													? doctor.lastName.am
													: doctor.lastName.en)}
										</h5>
										<p>
											<strong>የጀርባ ታሪክ : </strong> {doctor.bio.am}
										</p>
										<p>
											<strong>ስፔሻላይዜሽን : </strong> {doctor.specialization}
										</p>

										<p>
											<strong>የምርመራ ቡድን : </strong>{" "}
											{departments &&
												departments.find(
													(loc) => loc.value === doctor.department
												) &&
												departments.find(
													(loc) => loc.value === doctor.department
												).label}
										</p>
										<p>
											<strong> ጾታ : </strong>{" "}
											{location.pathname.slice(-2) === "am"
												? doctor.gender === "male"
													? "ወንድ"
													: "ሴት"
												: doctor.gender}
										</p>
										<p>
											<strong>አድራሻ : </strong>{" "}
											{locations &&
												locations.find((loc) => loc.value === doctor.address) &&
												locations.find((loc) => loc.value === doctor.address)
													.label}
										</p>

										<p>
											<strong>ቋንቋዎች : </strong>{" "}
											{languages &&
												languages
													.filter((lang) =>
														doctor.languages.includes(lang.value)
													)
													.map((ele) => ele.label)
													.join(" , ")}
										</p>

										<p>
											{doctor.workingDaysSpecial.filter(
												(day) =>
													formatDate(new Date(day.day)) > formatDate(new Date())
											).length ? (
												<span style={{ fontWeight: "bold" }}> ልዩ ቀናት </span>
											) : null}{" "}
											{doctor.workingDaysSpecial
												.filter(
													(day) =>
														formatDate(new Date(day.day)) >
														formatDate(new Date())
												)
												.map((day, index) => (
													<div key={index}>
														<br />
														<span>Date : {formatDate(new Date(day.day))}</span>
														<br />

														<span>
															From{" "}
															{formatAMPM(
																day.availableHours[0].startHour.hour,
																day.availableHours[0].startHour.minute
															)}{" "}
															to{" "}
															{formatAMPM(
																day.availableHours[0].endHour.hour,
																day.availableHours[0].endHour.minute
															)}
														</span>
													</div>
												))}
										</p>
									</div>
								)}
							</div>

							<form onSubmit={makeAppointment} method='post'>
								<div className='two-by-two'>
									{!user && (
										<FormInput
											required
											name='firstName'
											placeholder='First Name'
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									)}
									{!user && (
										<FormInput
											required
											name='lastName'
											placeholder='Last Name'
											onChange={(e) => setLastName(e.target.value)}
										/>
									)}
									{!user && (
										<FormInput
											required
											name='age'
											placeholder='Age'
											type='number'
											onChange={(e) => setAge(e.target.value)}
										/>
									)}
									{!user && (
										<FormSelect
											onChange={(e) => setGender(e.target.value)}
											required
											name='gender'
										>
											<option value='first' disabled>
												Gender
											</option>
											<option value='male'>Male</option>
											<option value='female'>Female</option>
										</FormSelect>
									)}
									{!user && (
										<FormInput
											required
											name='email'
											placeholder='Email'
											type='email'
											onChange={(e) => setEmail(e.target.value)}
										/>
									)}
									{!user && (
										<PhoneInput
											phone
											className='form-control'
											placeholder='Enter phone number'
											value={phone}
											onChange={setPhone}
										/>
									)}
									{!user && (
										<FormInput
											required
											name='address'
											placeholder='Address'
											type='text'
											onChange={(e) => setAddress(e.target.value)}
										/>
									)}

									<FormInput
										required
										name='reason'
										type='text'
										placeholder='Reason'
										onChange={(e) => setReason(e.target.value)}
									></FormInput>

									<FormSelect
										required
										name='time'
										onChange={(e) => setApptTime(time[e.target.value])}
									>
										<option selected disabled>
											Select time
										</option>
										{time.map((schedule, index) => (
											<option key={index} value={index}>
												{`${formatAMPM(schedule.hour, schedule.minute)}
										`}
											</option>
										))}
									</FormSelect>

									<DatePicker
										className='form-control'
										selected={new Date(date)}
										onChange={(date) => setDate(formatDate(date))}
										renderDayContents={renderDayContents}
										style={{ width: "100%" }}
									/>
									{services.length && (
										<FormSelect
											onChange={(e) => setSelectedService(e.target.value)}
											required
											name='gender'
										>
											<option value='first' disabled>
												Select Service
											</option>
											{services.map((e) => (
												<option value={e._id}>
													{e.title.en}, {e.category}
												</option>
											))}
										</FormSelect>
									)}
								</div>

								<Button
									theme='success'
									className='mt-3'
									type='submit'
									disabled={!time.length || !apptTime}
								>
									አሁን ያዝ
								</Button>
							</form>
						</Card>
					</Col>
				</Row>
			) : (
				<Featching />
			)}
		</Container>
	);
};

export default MakeAppointmentAm;
