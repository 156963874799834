import { Button, Card, Col, Row } from "shards-react";
import { Container } from "./Doctors.styles";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Select from "react-select";
import doctorsApi from "../../api/doctors";
import Featching from "../../components/Featching/loading.page";
import { doctorsAdded } from "../../store/doctors-reducer";
import { searchParamsChanged } from "../../store/search-reducer";
import { formatAMPM } from "../../utils/formatAMPM";
import { formatDate } from "../../utils/formatDate";
import {
	formatDepartment,
	formatLanguage,
	formatLocation,
} from "../../utils/formatResponse";
const DoctorsAm = () => {
	const doctors = useSelector((state) => state.doctors);
	const location = useLocation();

	const departments = formatDepartment(
		useSelector((state) => state.departments),
		location.pathname.slice(-2) === "am"
	);

	const searchParams = useSelector((state) => state.searchReducer);
	const [searchParam, setSearchParams] = useState(searchParams);
	const [filterdDoctors, setFilterdDoctors] = useState([...doctors]);
	const [loading, setLoading] = useState(true);

	const languages = formatLanguage(useSelector((state) => state.language));
	const locations = formatLocation(useSelector((state) => state.location));

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSearch = (notfilterd) => {
		let filterd = doctors;
		if (
			!searchParam.gender &&
			!searchParam.location &&
			!searchParam.language &&
			!searchParam.department &&
			notfilterd
		) {
			return setFilterdDoctors(notfilterd);
		}
		if (searchParam.gender) {
			filterd = filterd.filter(
				(doc) => doc.gender.toLowerCase() === searchParam.gender.toLowerCase()
			);
		}
		if (searchParam.location) {
			filterd = filterd.filter(
				(doc) =>
					doc.address.toLowerCase() === searchParam.location.toLowerCase()
			);
		}
		if (searchParam.language) {
			filterd = filterd.filter((doc) =>
				doc.languages.join("").includes(searchParam.language)
			);
		}
		if (searchParam.department) {
			filterd = filterd.filter(
				(doc) =>
					doc.department.toLowerCase() === searchParam.department.toLowerCase()
			);
		}
		setFilterdDoctors(filterd);
	};

	const onClear = () => {
		setFilterdDoctors([...doctors]);
		dispatch(
			searchParamsChanged({
				department: "",
				language: "",
				gender: "",
				location: "",
			})
		);
		setSearchParams({
			department: "",
			language: "",
			gender: "",
			location: "",
		});
	};

	const fetchSuccess = (res) => {
		setLoading(false);
		dispatch(doctorsAdded(res.data.filter((doc) => doc.role !== "admin")));
		setFilterdDoctors(res.data.filter((doc) => doc.role !== "admin"));
		if (
			!searchParams.language &&
			!searchParams.gender &&
			!searchParams.location &&
			!searchParams.department
		) {
			setFilterdDoctors(res.data.filter((doc) => doc.role !== "admin"));
		}
		onSearch(res.data.filter((doc) => doc.role !== "admin"));
	};
	const onFaliure = (error) => {
		console.log("this went off", error);
	};

	useEffect(() => {
		setLoading(true);
		doctorsApi.getAll(fetchSuccess, onFaliure);

		// eslint-disable-next-line
	}, []);

	const handleChange = (e) => {
		if (e.name === "gender")
			setSearchParams({ ...searchParam, gender: e.value });
		else if (e.name === "department")
			setSearchParams({ ...searchParam, department: e.value });
		else if (e.name === "language")
			setSearchParams({ ...searchParam, language: e.value });
		else if (e.name === "location")
			setSearchParams({ ...searchParam, location: e.value });
	};

	return (
		<Container>
			<Row>
				<Col lg={3} md={3}>
					<Card className='form-card filter'>
						<h4>ፈልግ እና አጣራ</h4>
						<hr style={{ marginBottom: "30px" }} />

						<Select
							options={[
								{ label: "ወንድ", value: "male", name: "gender" },
								{ label: "ሴት", value: "female", name: "gender" },
							]}
							placeholder='
							ጾታ'
							onChange={handleChange}
						/>
						<br />
						<Select
							options={departments}
							placeholder='የምርመራ ቡድን'
							onChange={handleChange}
						/>

						<br />

						<Select
							options={languages}
							placeholder='ቋንቋ'
							onChange={handleChange}
						/>

						<br />

						<Select
							options={locations}
							placeholder='አድራሻ'
							onChange={handleChange}
						/>
						<br />

						<Button theme='success' className='mt-3' onClick={onSearch}>
							ፈልግ
						</Button>
						<Button theme='success' className='mt-3' onClick={onClear}>
							አስወግድ
						</Button>
					</Card>
				</Col>
				<Col lg={9} md={9} className='mobile-top'>
					{loading && <Featching />}
					{!loading && !filterdDoctors.length ? (
						<p
							style={{
								width: "50%",
								textAlign: "center",
								margin: "100px auto",
								fontSize: "22px",
							}}
						>
							ከተሰጡት ማጣሪያዎች ጋር ምንም ዶክተር የለም. እባክዎን ማጣሪያውን ይለውጡ ወይም ያጽዱ።
						</p>
					) : (
						filterdDoctors.map((doc, index) => (
							<Card
								className={`form-card doc-list ${index !== 0 && "mt-5"}`}
								key={index}
							>
								{doc.profilePicture ? (
									<img src={doc.profilePicture} alt='' className='doc-img' />
								) : (
									<img
										src={require("../../Assets/default.png")}
										alt=''
										className='doc-img'
									/>
								)}
								<div>
									<h5>
										{location.pathname.slice(-2) !== "am" ? "Dr.  " : "ዶ / ር "}
										{doc.firstName.en &&
											(location.pathname.slice(-2) === "am"
												? doc.firstName.am
												: doc.firstName.en)}
										{doc.lastName.am &&
											(location.pathname.slice(-2) === "am"
												? doc.lastName.am
												: doc.lastName.en)}
									</h5>
									<p>
										{" "}
										<strong>ስፔሻላይዜሽን : </strong> {doc.specialization}
									</p>
									<p>
										<strong>የምርመራ ቡድን : </strong>{" "}
										{departments &&
											departments.find((loc) => loc.value === doc.department) &&
											departments.find((loc) => loc.value === doc.department)
												.label}
									</p>
									<p>
										<strong> ጾታ : </strong>{" "}
										{location.pathname.slice(-2) === "am"
											? doc.gender === "male"
												? "ወንድ"
												: "ሴት"
											: doc.gender}
									</p>
									<p>
										<strong>አድራሻ : </strong>{" "}
										{locations &&
											locations.find((loc) => loc.value === doc.address) &&
											locations.find((loc) => loc.value === doc.address).label}
									</p>
									<p>
										<strong>ቋንቋዎች : </strong>{" "}
										{languages &&
											languages
												.filter((lang) => doc.languages.includes(lang.value))
												.map((ele) => ele.label)
												.join(" , ")}
									</p>

									<p>
										{doc.workingDaysSpecial.filter(
											(day) =>
												formatDate(new Date(day.day)) > formatDate(new Date())
										).length ? (
											<span style={{ fontWeight: "bold" }}>ልዩ ቀናት </span>
										) : null}
										{doc.workingDaysSpecial
											.filter(
												(day) =>
													formatDate(new Date(day.day)) > formatDate(new Date())
											)
											.map((day, index) => (
												<div key={index}>
													<br />
													<span>
														Date :
														{new Date(day.day).toISOString().substring(0, 10)}
													</span>
													<br />

													<span>
														From{" "}
														{formatAMPM(
															day.availableHours[0].startHour.hour,
															day.availableHours[0].startHour.minute
														)}{" "}
														to{" "}
														{formatAMPM(
															day.availableHours[0].endHour.hour,
															day.availableHours[0].endHour.minute
														)}
													</span>
												</div>
											))}
									</p>
								</div>

								<Button
									className='mt-1 book-botton'
									onClick={() => navigate(`/make-appointment/${doc._id}/am`)}
								>
									ቀጠሮ ይያዙ
								</Button>
							</Card>
						))
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default DoctorsAm;
