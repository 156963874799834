import styled from "styled-components";

import homeBackground from "../../Assets/home.png";

export const Container = styled.div`
	width: 100vw;

	max-width: 100vw;
	overflow-x: hidden;
	height: fit-content;
	text-align: center !important;
	h1 {
		margin-top: 100px;
	}

	.down,
	.right {
		font-size: 40px !important;
		margin-top: 100px;
		color: #15558d !important;
		margin-left: 50%;
		transform: translateX(-50%);
	}

	.down {
		display: none;
	}
	p {
		width: 100%;
		margin: 50px auto;
		margin-bottom: 0;
		text-align: center;
		text-align-last: center;
		margin-top: 10px;
	}
	.search {
		height: 100vh;
		background-image: url(${homeBackground});
		background-size: cover;
		background-position: -100px 0;
		position: relative;
		/* background-repeat:no-repeat; */
	}
	.services {
		height: fit-content;
		width: 85vw;
		margin: 0 auto !important;
		display: grid;
		grid-template-columns: 1fr 60px 1fr 60px 1fr 60px 1fr;
		grid-gap: 40px;
		padding: 40px;
	}
	.column {
		width: 100%;
		height: 100%;

		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;
		h5 {
			color: #fff !important;
			font-size: 20px;
		}
		svg {
			font-size: 40px !important;
			margin-left: 50%;
			transform: translateX(-50%);
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.flex {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: 10px;
	}
	h4 {
		text-align: center !important;
		width: 100%;
		padding: 0;
	}
	.column img {
		width: 60px;
		margin-left: 50%;
		transform: translateX(-50%);
	}
	.desc {
		width: 80vw;
		margin: 40px auto;
		text-align: center !important;
		margin-bottom: 0 !important;
	}
	@media screen and (max-width: 670px) {
		.services {
			grid-template-columns: 1fr;
			width: 80vw;
		}
		.mobile-right {
			margin: 0 !important;
			padding: 0 !important;
		}
		p {
			width: 100%;
			margin: 10px auto;
		}
		.column {
			height: fit-content;
		}
		svg {
			font-size: 40px !important;
			margin-top: 10px;
			margin-left: 50%;
			transform: translateX(-50%);
		}
		.down {
			display: block;
			margin-top: 0;
		}
		.right {
			display: none;
		}
	}
	h1 {
		font-size: 20px;
		line-height: 22px;
		margin-top: 100px;

		text-align: center !important;
	}
	.top-100 {
		margin-top: 0px;
	}
`;

export const Card = styled.div`
	width: 100%;

	float: right;

	h1 {
		color: #fff;
		font-weight: 900;
		text-align: right !important;
		line-height: 60px;
		font-size: 50px;
	}
	.orange {
		color: #f2a54a;
	}
	@media screen and (max-width: 670px) {
		width: 60vw;
		h1 {
			font-size: 22px;
			line-height: 27px;
		}
		select {
			margin-top: 20px !important;
		}
	}
	@media screen and (max-width: 470px) {
		width: 100vw;
		h1 {
			font-size: 22px;
		}
	}
`;

export const Button = styled.div`
	width: 100%;

	background-color: #15558d;
	color: #fff;
	font-size: 22px;
	padding: 7px 20px;
	text-align: center;
	border-radius: 18px;
`;

export const Stats = styled.div`
	width: 100vw;
	height: fit-content;
	background-color: #15558d;
	margin-top: 100px;
	padding: 60px 10vw;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	color: #fff;
	text-align: center !important;

	p {
		color: #fff;
		width: fit-content;
		margin: 0 auto;
		font-size: 28px;
		font-weight: 600;
		padding: 0;
	}
	@media screen and (max-width: 470px) {
		grid-template-columns: 1fr;
	}
`;

export const FilterContainer = styled.div`
	width: 90vw;
	height: fit-content;
	position: absolute;
	bottom: 180px;
	left: 5vw;
	display: flex;
	flex-direction: column;
	.grid {
		display: grid;
		grid-gap: 25px;
		background-color: #fff;
		grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
	}
	@media screen and (max-width: 600px) {
		.grid {
			display: grid;
			grid-gap: 25px;
			background-color: transparent;
			grid-template-columns: 1fr;
		}
	}
	.css-1s2u09g-control {
		height: 70px !important;
		border-radius: 0;
		outline: none;
		box-shadow: none !important;

		/* border-radius: 100px; */
	}
	@media screen and (min-width: 600px) {
		.css-1s2u09g-control {
			height: 70px !important;
			border-left: none;
			border-top: none;
			border-bottom: none;
			border-radius: 0;
			outline: none;
			box-shadow: none !important;

			/* border-radius: 100px; */
		}
	}
	.find {
		border-radius: 5px;
		min-height: 40px;
		height: auto;
		float: left;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 10px;
		font-weight: bold;
		font-size: 16px;
		background-color: #17c671;
		border: none;
		color: #fff;
	}
	@media screen and (max-width: 600px) {
		.css-1s2u09g-control {
			height: 40px !important;

			/* border-radius: 100px; */
		}
		.grid {
			display: grid;
			grid-gap: 25px;
			background-color: transparent;
			grid-template-columns: 1fr;
		}
	}
`;
