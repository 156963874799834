import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "schedules",
  initialState: [],
  reducers: {
    schedulesAdded: (schedules, action) => {
      schedules.length === 0 && action.payload.map((sc) => schedules.push(sc));
    },
  },
});

export const { schedulesAdded } = slice.actions;

export default slice.reducer;
