import { Container } from "./Footer.styles";
import { GoLocation } from "react-icons/go";
import { AiOutlinePhone, AiFillMail } from "react-icons/ai";
const Footer = () => {
	return (
		<Container>
			<div className='top'>
				<img
					src={require("../../Assets/logo.png")}
					alt=''
					className='logo__footer'
				/>
				<p>
					<GoLocation /> Germany <br /> Schellingstrasse 22
					<br /> 80799 Munich
					<br />
					<AiOutlinePhone /> +49 160 9150 1935 <br />
					<a
						href='
						mailto:adey@adey-meselesh.de	'
					>
						<AiFillMail /> adey@adey-meselesh.de
					</a>
				</p>
				<p>
					<GoLocation /> Ethiopia <br /> Bole Subcity Woreda 12 <br /> House
					number: New, Addis Ababa, Ethiopia <br />
					<AiOutlinePhone /> +251 980 446 619 <br />
					<a href='	mailto:ethiopia@adey-meselesh.de'>
						<AiFillMail /> ethiopia@adey-meselesh.de
					</a>
				</p>
				<p>
					<GoLocation /> Portugal <br /> Adey Meselesh GmbH, Sucursal EM
					Portugal
					<br />
					Rua Dom Francisco Gomesde Avelar, <br />7 8670-083 Aljezur, Portugal{" "}
					<br />
					<a href='mailto:portugal@adey-meselesh.de'>
						<AiFillMail /> portugal@adey-meselesh.de
					</a>
				</p>
			</div>
			<p>Copyright © 2022 Adey Meselesh GmbH</p>
		</Container>
	);
};

export default Footer;
