import React from "react";
import { Alert, Button } from "shards-react";

const CustomAlert = ({ setClose, message, type }) => {
	return (
		<Alert
			className='mb-0'
			theme={`${type === "success" ? "success" : "secondary"}`}
			style={{
				display: "grid",
				gridTemplateColumns: " 1fr 50px",
				gridGap: "20px",
			}}
		>
			<div>
				<i className='fa fa-info mx-2'></i>
				{message}
			</div>

			<Button
				theme='danger'
				style={{
					float: "right",
					width: "30px",
					height: "30px",
					padding: "0",
				}}
				onClick={() => setClose(false)}
			>
				X
			</Button>
		</Alert>
	);
};

export default CustomAlert;
