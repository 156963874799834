import { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import Clock from "react-live-clock";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Dropdown, DropdownItem, DropdownMenu } from "shards-react";
import userApi from "../../api/user";
import { userSignedIn, userSignedOut } from "../../store/user-reducer";
import { formatDate } from "../../utils/formatDate";
import MobileNav from "./mobile";
import { Bars, Nav, NavLink, NavMenu, Toggle } from "./NavbarElements";
const Navbar = () => {
	const user = useSelector((state) => state.user[0]);
	const [open, setOpen] = useState(false);
	const toggle = () => {
		setOpen(!open);
	};

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const location = useLocation();

	const onLogout = () => {
		dispatch(userSignedOut([]));
		sessionStorage.removeItem("token");
		navigate("/");
	};

	useEffect(() => {
		let id = sessionStorage.getItem("id");
		id &&
			userApi.getOne(
				id,
				(resp) => dispatch(userSignedIn(resp.data.patient)),
				(error) => console.log("this went off", error)
			);
		// eslint-disable-next-line
	}, []);

	const changeLanguage = () => {
		if (location.pathname.slice(-2) !== "am") {
			if (location.pathname === "/") {
				navigate(`/am`);
			} else navigate(`${location.pathname}/am`);
		} else {
			navigate(location.pathname.slice(0, -3));
		}
	};

	return (
		<>
			<MobileNav />
			<Nav className='desktop'>
				<NavLink to='/'>
					<img
						src={require("../../Assets/logo.png")}
						alt='logo'
						style={{ height: "50px" }}
					/>
				</NavLink>
				<Bars />
				<NavMenu>
					<div className='flex'>
						<p>Now : {formatDate(new Date())}</p>
						<Clock
							format={"HH:mm:ss"}
							ticking={true}
							// timezone={"Ethiopia"}
						/>
						<button onClick={changeLanguage} className='language'>
							{location.pathname.slice(-2) !== "am" ? "አማ" : "En"}
						</button>
					</div>
					<div className='d-flex'>
						<NavLink to='/'>HOME</NavLink>

						<NavLink to='/appointments'>Appointments</NavLink>
						<NavLink to='/doctors'>Doctors</NavLink>
						{!user &&
							(location.pathname === "/sign-in" ? (
								<NavLink to='/sign-up'>Sign-up</NavLink>
							) : (
								<NavLink to='/sign-in'>Sign-In</NavLink>
							))}
						{user && (
							<Dropdown open={open} toggle={toggle}>
								<Toggle className='uppercase'>
									{user.userName}

									<BsFillPersonFill />
								</Toggle>
								<DropdownMenu style={{ marginTop: "100px!important" }}>
									<DropdownItem onClick={() => navigate("/edit-profile")}>
										Edit Profile
									</DropdownItem>
									<DropdownItem onClick={onLogout}>Logout</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						)}
					</div>
				</NavMenu>
			</Nav>
		</>
	);
};

export default Navbar;
