import { Container } from "./Footer.styles";
import { GoLocation } from "react-icons/go";
import { AiOutlinePhone, AiFillMail } from "react-icons/ai";
const FooterAm = () => {
	return (
		<Container>
			<div className='top'>
				<img
					src={require("../../Assets/logo.png")}
					alt=''
					className='logo__footer'
				/>
				<p>
					<GoLocation /> ጀርመን <br /> Schellingstrasse 22
					<br /> 80799 ሙኒክ
					<br />
					<AiOutlinePhone /> +49 160 9150 1935 <br />
					<a
						href='
						mailto:adey@adey-meselesh.de	'
					>
						<AiFillMail /> adey@adey-meselesh.de
					</a>
				</p>
				<p>
					<GoLocation /> ኢትዮጵያ <br /> ቦሌ ክፍለ ከተማ ወረዳ 12
					<br /> ቤት ቁጥር: አዲስ, አዲስ አበባ, ኢትዮጵያ
					<br />
					<AiOutlinePhone /> +251 980 446 619 <br />
					<a href='	mailto:ethiopia@adey-meselesh.de'>
						<AiFillMail /> ethiopia@adey-meselesh.de
					</a>
				</p>
				<p>
					<GoLocation /> ፖርቹጋል <br />
					Adey Meselesh GmbH, Sucursal EM ፖርቹጋል
					<br />
					ሩዋ ዶም ፍራንሲስኮ ጎሜስዴ አቬላር፣ <br />7 8670-083 አልጄዙር፣ ፖርቱጋል <br />
					<a href='mailto:portugal@adey-meselesh.de'>
						<AiFillMail /> portugal@adey-meselesh.de
					</a>
				</p>
			</div>
			<p>የቅጂ መብት © 2022 ዐደይ መሰለሽ GmbH</p>
		</Container>
	);
};

export default FooterAm;
