import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { DropdownToggle } from "shards-react";
export const Nav = styled.nav`
	background: #15558d;
	height: 80px;
	display: flex;
	justify-content: space-between;
	/* padding: 0.5rem calc((100vw - 1100px) / 2); */
	padding: 0.5rem 5vw;
	z-index: 0;
	position: relative;
	a:hover {
		color: #fff !important;
		text-decoration: none !important;
	}
	/* Third Nav */
	/* justify-content: flex-start; */
	.dropdown-menu.show {
		margin-top: 29px !important;
	}
	.uppercase {
		text-transform: capitalize;
	}
`;

export const NavLink = styled(Link)`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		color: #15cdfc;
	}
`;
export const Toggle = styled(DropdownToggle)`
	color: #fff;
	display: flex;
	background-color: transparent;
	border: none;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	svg {
		margin-left: 10px !important;
	}
	cursor: pointer;
	&:active {
		background-color: transparent !important;
		border: none !important;
	}
	&:hover {
		background-color: transparent;
	}
	&:focus {
		background-color: transparent;
	}
`;

export const Bars = styled(FaBars)`
	display: none;
	color: #fff;
	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}
`;

export const NavMenu = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-right: -24px;
	/* Second Nav */
	/* margin-right: 24px; */
	/* Third Nav */
	/* width: 100vw;
  white-space: nowrap; */
	color: #fff;

	.flex * {
		padding: 0;
		margin: 0;
		float: right !important;
	}
	.d-flex {
		display: flex;
		padding-top: 7px;
	}
	.flex {
		display: flex;
		margin-left: 160px;
		p {
			margin-right: 20px;
		}
	}
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 24px;
	/* Third Nav */
	/* justify-content: flex-end;
  width: 100vw; */
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavBtnLink = styled(Link)`
	border-radius: 4px;
	background: #256ce1;
	padding: 10px 22px;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	/* Second Nav */
	margin-left: 24px;
	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #010606;
	}
`;
