import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer.component";
import Navbar from "./components/Navbar";
import CustomAlert from "./components/Notification/Notification.component";
import Appointments from "./Pages/Appointments/Appointments.pages";
import Doctors from "./Pages/Doctors/Doctors.pages";
import Home from "./Pages/Home/Home.page";
import LogIn from "./Pages/Login/Login";
import MakeAppointment from "./Pages/Make-appointments/Make-appointmets.pages";
import SignUp from "./Pages/Sign-up/Sign-up";

import { useDispatch, useSelector } from "react-redux";
import departmentsApi from "./api/departments";
import doctorsApi from "./api/doctors";
import languageApi from "./api/language";
import loactionApi from "./api/location";
import Activate from "./Pages/Activate-account/Activate";
import EditProfile from "./Pages/Edit-profile/Edit-profile";
import ForgotPassword from "./Pages/Forgot-password/ForgotPassword";
import ResetPassword from "./Pages/Reset-password/ResetPassword";
import { departmentsAdded } from "./store/departments-reducer";
import { doctorsAdded } from "./store/doctors-reducer";
import { languagesAdded } from "./store/language-reducer";
import { locationAdded } from "./store/location-reducer";
import RescheduleAppointment from "./Pages/Reschedule-appointments/Reschedule-appointmets.pages";
import HomeAm from "./Pages/HomeAm/HomeAm.page";
import NavbarAm from "./components/NavbarAm";
import FooterAm from "./components/FooterAm/Footer.component";
import AppointmentsAm from "./Pages/AppointmentsAm/Appointments.pages";
import DoctorsAm from "./Pages/DoctorsAm/Doctors.pages";
import LogInAm from "./Pages/LoginAm/Login";
import SignUpAm from "./Pages/Sign-upAm/Sign-up";
import MakeAppointmentAm from "./Pages/Make-appointmentsAm/Make-appointmets.pages";
import RescheduleAppointmentAm from "./Pages/Reschedule-appointmentsAm/Reschedule-appointmets.pages";
import EditProfileAm from "./Pages/Edit-profileAm/Edit-profile";

function App() {
	const [close, setClose] = useState(false);
	const [registerd, setRegisterd] = useState(false);
	const [reset, setReset] = useState(false);
	const user = useSelector((state) => state.user[0]);
	const handeleToggle = () => {
		setRegisterd(true);
	};
	useEffect(() => {
		setTimeout(() => setClose(true), 2000);
	}, []);

	const location = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		departmentsApi.getActive(departmentTimeSuccess, onFailure);
		doctorsApi.getAll(doctorsSuccess, onFailure);
		languageApi.getAll(languageSuccess, onFailure);
		loactionApi.getAll(locationSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const doctorsSuccess = (res) => {
		dispatch(doctorsAdded(res.data.filter((doc) => doc.role !== "admin")));
	};
	const languageSuccess = (res) => {
		dispatch(languagesAdded(res.data.languages));
	};
	const locationSuccess = (res) => {
		dispatch(locationAdded(res.data.addresses));
	};

	const departmentTimeSuccess = (res) => {
		dispatch(departmentsAdded(res.data.departments));
	};
	const onFailure = (error) => {
		console.log("error: ", error);
	};

	return (
		<div className='main-container'>
			{close && !user && !registerd && !reset && (
				<CustomAlert
					setClose={setClose}
					style={{ display: "none" }}
					message={
						location.pathname.slice(-2) !== "am"
							? "You have requested for a passowrd reset, please check your email"
							: "ሐኪም በያዙ ቁጥር የግል መረጃዎትን  እንዳንጠይቆት አባኮትን ይግቡ"
					}
				/>
			)}

			{location.pathname.slice(-2) !== "am" ? <Navbar /> : <NavbarAm />}
			{registerd && !user && (
				<CustomAlert
					setClose={setRegisterd}
					style={{ display: "none" }}
					message='You have succesfully registerd please check your email to activate your account'
					type='success'
				/>
			)}

			{reset && !user && (
				<CustomAlert
					setClose={setReset}
					style={{ display: "none" }}
					message='You have requested for a passowrd reset, please check your email'
					type='success'
				/>
			)}

			<Routes>
				{/* <Route path='*' element={<Home />} /> */}
				<Route path='/' element={<Home />} />
				<Route path='/am' element={<HomeAm />} />
				<Route path='/user/reset/:id' element={<ResetPassword />} />
				<Route path='/appointments' element={<Appointments />} />
				<Route path='/appointments/am' element={<AppointmentsAm />} />
				<Route path='/doctors' element={<Doctors />} />
				<Route path='/doctors/am' element={<DoctorsAm />} />
				<Route path='/make-appointment/:id' element={<MakeAppointment />} />
				<Route
					path='/make-appointment/:id/am'
					element={<MakeAppointmentAm />}
				/>
				<Route
					path='/reschedule-appointment/:id/:doctor/:lang'
					element={<RescheduleAppointmentAm />}
				/>
				<Route
					path='/reschedule-appointment/:id/:doctor'
					element={<RescheduleAppointment />}
				/>
				<Route path='/edit-profile' element={<EditProfile />} />
				<Route path='/edit-profile/am' element={<EditProfileAm />} />
				<Route path='/sign-in' element={<LogIn />} />
				<Route path='/sign-in/am' element={<LogInAm />} />
				<Route
					path='/sign-up'
					element={<SignUp handeleToggle={handeleToggle} />}
				/>

				<Route
					path='/sign-up/am'
					element={<SignUpAm handeleToggle={handeleToggle} />}
				/>
				<Route path='/patient/activate-account/:id' element={<Activate />} />
				<Route
					path='/user/forgot-password'
					element={<ForgotPassword setReset={setReset} />}
				/>
			</Routes>

			{location.pathname.slice(-2) !== "am" ? <Footer /> : <FooterAm />}
		</div>
	);
}

export default App;
