import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	overflow-x: hidden;
	padding: 50px;
	.filter {
		height: fit-content;
		width: 90%;
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		padding-bottom: 30px;
	}
	.doc-list {
		height: 200px;
		padding: 30px;
		display: grid;
		grid-template-columns: 200px 200px 1fr 1fr;
		grid-gap: 20px;
		.doc-img {
			height: 150px;
			max-height: 45vh;
			width: 200px;
			object-fit: cover;
		}
	}
	h4 {
		padding: 10px 10px;
		text-align: center;
	}
	hr {
		padding: 0;
		margin: 0;
	}
	@media screen and (max-width: 670px) {
		padding: 15px;
		.filter {
			width: 100%;
			margin-bottom: 50px;
		}
		.doc-list {
			grid-template-columns: 1fr;
			height: fit-content;
			.doc-img {
				width: 100%;
				height: auto;
				max-height: 45vh;
			}
		}
	}
`;
