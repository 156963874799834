import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./user-reducer";
import doctorsReducer from "./doctors-reducer";
import scheduleReducer from "./schedule-reducer";

import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import departmentsReducer from "./departments-reducer";
import searchReducer from "./search-reducer";
import locationReducer from "./location-reducer";
import languageReducer from "./language-reducer";
const persistConfig = {
	key: "counter",
	storage,
};
const reducers = combineReducers({
	user: userReducer,
	doctors: doctorsReducer,
	schedule: scheduleReducer,
	departments: departmentsReducer,
	searchReducer: searchReducer,
	location: locationReducer,
	language: languageReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

const reducer = () => {
	return configureStore({
		reducer: persistedReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}),
	});
};

export default reducer;
