import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	Form,
	FormFeedback,
	FormInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "shards-react";
import login from "../../Assets/login.png";
import { Container } from "../Login/Login.styles";

import { ToastContainer } from "react-toastify";

import userApi from "../../api/user";

const ResetPassword = () => {
	const [password, setPassword] = useState("");
	const [confirmPasword, setConfirmPassword] = useState("");
	const [match, setMatch] = useState("");
	const params = useParams();
	const navigate = useNavigate();
	useEffect(() => {
		if (password === confirmPasword && password.length > 1) {
			setMatch(false);
		} else if (
			password !== confirmPasword &&
			password.length > 1 &&
			confirmPasword.length > 1
		) {
			setMatch(true);
		}
	}, [password, confirmPasword]);

	const handleReset = (e) => {
		e.preventDefault();
		if (password === confirmPasword) {
			userApi.resetPassword(
				{
					password: password,
				},
				onSuccess,
				onFaileure,
				params.id
			);
		} else {
			setMatch(true);
		}
	};

	const onFaileure = (error) => {
		console.log(error);
	};

	const onSuccess = (data) => {
		console.log(data);
		setTimeout(() => {
			navigate("/sign-in");
		}, 3000);
	};

	return (
		<Container>
			<ToastContainer />
			<div className='two-by-two'>
				<img src={login} className='login-vector' alt='home vector' />

				<Form
					onSubmit={handleReset}
					method='post'
					className='form-card'
					style={{ height: "100px!important" }}
				>
					<Card className='form-card'>
						<Row>
							<h2 style={{ marginBottom: "30px" }}>Reset Your password </h2>
						</Row>

						<Row>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>lock</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder='New Password'
									invalid={match}
									valid={match === false}
									type='password'
									required
								/>
							</InputGroup>

							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>lock</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={confirmPasword}
									onChange={(e) => {
										setConfirmPassword(e.target.value);
									}}
									placeholder='Confirm Password'
									invalid={match}
									valid={match === false}
									type='password'
									required
								/>
							</InputGroup>
							<FormFeedback>Passwords didn't match </FormFeedback>
						</Row>

						<Row style={{ margin: "10px auto" }}>
							<Button theme='success' type='submit'>
								Save
							</Button>
						</Row>
						<Row
							style={{
								display: "flex!important",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<span></span>
							<Link style={{ float: "right" }} to='/sign-in'>
								Login
							</Link>
						</Row>
					</Card>
				</Form>
			</div>
		</Container>
	);
};

export default ResetPassword;
