import {
	Card as CustomCard,
	Container,
	FilterContainer,
	Stats,
} from "./home.styles";

import { useState } from "react";
import { BsArrowDown, BsArrowRight, BsClock } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Card } from "shards-react";
import AvailableDoctors from "../../components/Available-doctors/Available-doctors.component";
import CustomSlider from "../../components/Slider/slider.component";
import Select from "react-select";
import { searchParamsChanged } from "../../store/search-reducer";
import {
	formatDepartment,
	formatLanguage,
	formatLocation,
} from "../../utils/formatResponse";
const HomeAm = () => {
	const departments = formatDepartment(
		useSelector((state) => state.departments),
		true
	);
	const languages = formatLanguage(useSelector((state) => state.language));
	const locations = formatLocation(useSelector((state) => state.location));

	const [searchParams, setSearchParams] = useState({
		department: "",
		language: "",
		gender: "",
		location: "",
	});

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const onSearch = () => {
		dispatch(searchParamsChanged(searchParams));
		navigate("/doctors");
	};
	const handleChange = (e) => {
		if (e.name === "gender")
			setSearchParams({ ...searchParams, gender: e.value });
		else if (e.name === "department")
			setSearchParams({ ...searchParams, department: e.value });
		else if (e.name === "language")
			setSearchParams({ ...searchParams, language: e.value });
		else if (e.name === "location")
			setSearchParams({ ...searchParams, location: e.value });
	};
	return (
		<Container>
			<div className='search'>
				<FilterContainer>
					<CustomCard>
						<h1 className='bold-title'>
							ሐኪም ይፈልጉ ፣
							<span className='orange'>
								<br />
								አሁን ቀጠሮ ይያዙ
							</span>
						</h1>
					</CustomCard>

					<div className='grid'>
						<Select
							options={[
								{ label: "ወንድ", value: "male", name: "gender" },
								{ label: "ሴት", value: "female", name: "gender" },
							]}
							placeholder='
							ጾታ'
							onChange={handleChange}
						/>
						<Select
							options={departments}
							placeholder='የምርመራ ቡድን'
							onChange={handleChange}
						/>
						<Select
							options={languages}
							placeholder='ቋንቋ'
							onChange={handleChange}
						/>
						<Select
							options={locations}
							placeholder='አድራሻ'
							onChange={handleChange}
						/>
						<button className='find' onClick={onSearch}>
							አጣራ
						</button>
					</div>
				</FilterContainer>
			</div>
			<Card className='form-card ml-5 mr-5 services'>
				<div className='column'>
					<h4>
						ዶክተር
						<br /> ይምረጡ
					</h4>

					<p>እኛ ጋር በጣም ጥሩ ዶክተሮች አሉን። የሚመቾትን ዶክተር ይምረጡ።</p>
				</div>
				<BsArrowRight className='right' />
				<BsArrowDown className='down' />
				<div className='column'>
					<h4>መለያ ፍጠር</h4>

					<p>በጥቂት እርምጃዎች፣ ቀጠሮዎችዎን ማስተዳደር የሚችሉበት መለያ መፍጠር ይችላሉ።</p>
				</div>
				<BsArrowRight className='right' />
				<BsArrowDown className='down' />

				<div className='column'>
					<h4>ቀጠሮ ይያዙ</h4>

					<p>የዶክተሮችን ተገኝነት ማየት እና በፍጥነት እና በቀላሉ ቀጠሮ መያዝ ይችላሉ።</p>
				</div>
				<BsArrowRight className='right' />
				<BsArrowDown className='down' />

				<div className='column'>
					<h4>ዶክተርዎን ይጎብኙ</h4>

					<p>በመጨረሻም የመረጡትን ዶክተር ማየት ይችላሉ። መልካሙን ሁሉ እንመኝሎታለን።</p>
				</div>
			</Card>
			<Stats>
				<div className='column'>
					<BsClock />
					<h5>በፍጥነት ቀጠሮ ያግኙ</h5>
				</div>
				<div className='column'>
					<img src={require("../../Assets/heart.png")} alt='' />
					<h5>በጣም ጥሩ ዶክተሮችን ያግኙ</h5>
				</div>
				<div className='column'>
					<MdManageAccounts />
					<h5>ቀጠሮዎችዎን በቀላሉ ያስተዳድሩ</h5>
				</div>
			</Stats>
			<h1>አደይ ቀጠሮ ፡ ለጤናዎ</h1>
			<p className='desc' style={{ textAlign: "center!improtant" }}>
				ብዙውን ጊዜ ትክክለኛውን ሐኪም ማግኘት በጣም ቀላል አይደለም. አደይ ቀጠሮ የተለያዩ ልዩ ልዩ ባለሙያዎችን
				ያቀርብልዎታል ይህ ማለት ለፍላጎትዎ ትክክለኛውን ዶክተር በፍጥነት እና በቀላሉ ማግኘት እና በቀጥታ መያዝ ይችላሉ.
				እና ይህ አገልግሎት ለእርስዎ ከክፍያ ነጻ ነው.
			</p>
			<CustomSlider />
			<AvailableDoctors />
		</Container>
	);
};

export default HomeAm;
