import { apiCall, apiFetch } from "../api/ApiCall";
const userApi = {
	getOne: (id, onSuccess, onFailure) => {
		apiFetch("get", `/patient/fetch_profile/${id}`, onSuccess, onFailure);
	},
	getAll: (onSuccess, onFailure) => {
		apiFetch("get", `/user/`, onSuccess, onFailure);
	},
	login: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/patient/login", onSuccess, onFailure);
	},
	getRefreshToken: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/patient/refresh-token", onSuccess, onFailure);
	},
	logout: (onSuccess, onFailure) => {
		apiFetch("get", "/patient/logout", onSuccess, onFailure);
	},
	activate: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/patient/activate-account", onSuccess, onFailure);
	},
	changePassword: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/patient/change-password", onSuccess, onFailure);
	},
	forgetPassword: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/patient/forgot-password", onSuccess, onFailure);
	},
	resetPassword: (data, onSuccess, onFailure, id) => {
		apiCall("post", data, "/patient/reset-password", onSuccess, onFailure, id);
	},
	update: (data, id, onSuccess, onFailure) => {
		apiCall("put", data, `/patient/update_profile/${id}`, onSuccess, onFailure);
	},
	deleteOne: (id, onSuccess, onFailure) => {
		apiFetch("delete", `/user/${id}`, onSuccess, onFailure);
	},
	register: (data, onSuccess, onFailure) => {
		apiCall("post", data, "/patient/register", onSuccess, onFailure);
	},
};

export default userApi;
