import { BsDot } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card } from "shards-react";
import { Container } from "./Available-doctors.styles";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const AvailableDoctors = () => {
	let navigate = useNavigate();

	const doctors = useSelector((state) => state.doctors);
	const options = {
		loop: true,

		responsive: {
			0: {
				items: 1,
			},
			700: {
				items: 2,
			},
			1000: {
				items: 3,
			},
		},
	};

	const location = useLocation();

	return (
		<Container
			style={{
				opacity: !doctors.length && 0,
				height: !doctors.length && 0,
				padding: !doctors.length && 0,
				margin: !doctors.length && 0,
			}}
		>
			{location.pathname.slice(-2) !== "am" ? (
				<h1>Book Our Doctors</h1>
			) : (
				<h1>ከዶክተሮቻችን ጋር ቀጠሮ ይያዙ</h1>
			)}

			{/* <div className='docs-container'> */}
			<OwlCarousel
				className='owl-theme docs-container'
				loop
				margin={20}
				autoplay
				autoplayTimeout={2500}
				{...options}
			>
				{doctors.map((doc, index) => (
					<Card className='form-card  doc' key={index}>
						{doc.profilePicture ? (
							<img src={doc.profilePicture} alt='' className='doc-img' />
						) : (
							<img
								src={require("../../Assets/default.png")}
								alt=''
								className='doc-img'
							/>
						)}
						<div className='desc-container'>
							<h4>
								{location.pathname.slice(-2) !== "am" ? "Dr.  " : "ዶ / ር "}
								{doc.firstName.en &&
									(location.pathname.slice(-2) === "am"
										? doc.firstName.am
										: doc.firstName.en)}{" "}
								{doc.lastName.am &&
									(location.pathname.slice(-2) === "am"
										? doc.lastName.am
										: doc.lastName.en)}
							</h4>{" "}
							<span className='long-text'>{doc.specialization}</span>
							<span className='mt-4'>
								{/* Available
								<BsDot
									style={{
										color: "green",
										fontSize: "50px",
										marginLeft: "-15px",
									}}
								/> */}
								<Button
									className='mt-1'
									style={{ backgroundColor: "#0DE0FE", border: "none" }}
									onClick={() => navigate(`/make-appointment/${doc._id}`)}
								>
									Book now
								</Button>
							</span>
						</div>{" "}
					</Card>
				))}
			</OwlCarousel>
			{/* </div> */}
		</Container>
	);
};

export default AvailableDoctors;
