import { apiCall, apiFetch } from "./ApiCall";
const doctorsApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/doctor", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/doctor/available-doctors", onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/doctor/${id}`, onSuccess, onFailure),
	updateDoctor: (id, onSuccess, onFailure) =>
		apiCall("put", `/doctor/${id}`, onSuccess, onFailure),
	getAppointment: (id, onSuccess, onFailure) =>
		apiFetch("get", `/appointment/get-by-doctor/${id}`, onSuccess, onFailure),
};

export default doctorsApi;
