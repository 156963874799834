import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Card, Col, FormSelect, Row } from "shards-react";
import { Container } from "./Reschedule-appointmets.styles";

import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-phone-number-input/style.css";
import appointmentApi from "../../api/appointment";
import Featching from "../../components/Featching/loading.page";
import { formatAMPM } from "../../utils/formatAMPM";
import { formatDate } from "../../utils/formatDate";

const RescheduleAppointmentAm = () => {
	const [days, setDays] = useState([]);
	const [date, setDate] = useState(
		days && formatDate(new Date())
		// getting the next day
	);
	const [time, setTime] = useState([]);
	const [apptTime, setApptTime] = useState(time && time[0]);
	const params = useParams();
	const [fetch, setFetch] = useState();

	const navigate = useNavigate();
	const doctor = useSelector((state) =>
		state.doctors.find((doc) => doc._id === params.doctor)
	);

	const convertor = (date) => {
		var today = new Date(date);
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + "-" + mm + "-" + dd;
		return today;
	};
	const renderDayContents = (day, date) => {
		const tooltipText = `Tooltip for date: ${date}`;
		return (
			<div>
				{days.includes(convertor(date)) ? (
					<span title={tooltipText}>{new Date(date).getDate()}</span>
				) : (
					<span className='inactive' title={tooltipText}>
						{new Date(date).getDate()}
					</span>
				)}
			</div>
		);
	};

	useEffect(() => {
		appointmentApi.getTimeByDoctor(
			params.doctor,
			formatDate(new Date(date)),
			fetchWorkingTimeSuccess,
			onFaileure
		);
		// eslint-disable-next-line
	}, [date, fetch]);

	useEffect(() => {
		appointmentApi.getDateByDoctor(
			params.doctor,
			fetchWorkingDateSuccess,
			(error) => console.log("error", error)
		);
		// eslint-disable-next-line
	}, [fetch]);

	const fetchWorkingDateSuccess = (resp) => {
		setDays(resp.data.availableDates);
		resp.data.availableDates.length &&
			setDate(new Date(resp.data.availableDates[0]));
	};
	const fetchWorkingTimeSuccess = (resp) => {
		setTime(resp.data.available_hours);
	};

	const rescheduleAppointment = (e) => {
		e.preventDefault();

		let data = {
			appointmentDate: date,
			appointmentTime: {
				hour: apptTime.hour,
				minute: apptTime.minute,
			},
		};
		toast.promise(
			appointmentApi.updateAppointment(data, params.id, onSuccess, onFaileure),
			{
				pending: "Rescheduling Appointment ...",
			}
		);
	};

	const onFaileure = (error) => {
		setTime([]);
	};
	const location = useLocation();
	const onSuccess = (data) => {
		setTimeout(() => {
			if (sessionStorage.getItem("id")) {
				navigate("/appointments");
			} else {
				navigate("/doctors");
			}
		}, 3000);

		setFetch(!fetch);
	};

	return (
		<Container>
			<ToastContainer />{" "}
			{doctor ? (
				<Row>
					<Col lg={12} md={12}>
						<Card className='form-card  '>
							<div className='doc-list'>
								{doctor && doctor.profilePicture ? (
									<img src={doctor.profilePicture} alt='' className='doc-img' />
								) : (
									<img
										src={require("../../Assets/default.png")}
										alt=''
										className='doc-img'
									/>
								)}
								{doctor && (
									<div>
										<h5>
											{location.pathname.slice(-2) !== "am"
												? "Dr.  "
												: "ዶ / ር "}
											{doctor.firstName.en &&
												(location.pathname.slice(-2) === "am"
													? doctor.firstName.am
													: doctor.firstName.en)}
											{doctor.lastName.am &&
												(location.pathname.slice(-2) === "am"
													? doctor.lastName.am
													: doctor.lastName.en)}
										</h5>
										<p>
											<strong>የህይወት ታሪክ : </strong>{" "}
											{location.pathname.slice(-2) === "am"
												? doctor.bio.am
												: doctor.bio.en}
										</p>
										<p>
											<strong>ስፔሻላይዜሽን : </strong> {doctor.specialization}
										</p>

										<p>
											<strong> ጾታ : </strong>{" "}
											{location.pathname.slice(-2) === "am"
												? doctor.gender === "male"
													? "ወንድ"
													: "ሴት"
												: doctor.gender}
										</p>
									</div>
								)}
							</div>
							<form onSubmit={rescheduleAppointment} method='post'>
								<div className='two-by-two'>
									<FormSelect
										required
										name='time'
										onChange={(e) => setApptTime(time[e.target.value])}
									>
										<option selected disabled>
											Select time
										</option>
										{time.map((schedule, index) => (
											<option key={index} value={index}>
												{`${formatAMPM(schedule.hour, schedule.minute)}
										`}
											</option>
										))}
									</FormSelect>

									<DatePicker
										className='form-control'
										selected={new Date(date)}
										onChange={(date) => setDate(formatDate(date))}
										renderDayContents={renderDayContents}
										style={{ width: "100%" }}
									/>
									<Button
										theme='success'
										type='submit'
										disabled={!time.length || !apptTime}
									>
										Reschedule
									</Button>
								</div>
							</form>
						</Card>
					</Col>
				</Row>
			) : (
				<Featching />
			)}
		</Container>
	);
};

export default RescheduleAppointmentAm;
