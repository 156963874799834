import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, Col, FormCheckbox, FormInput, FormSelect } from "shards-react";
import CustomFileUpload from "../../components/Custom-file-upload/custom-file-upload";
import { Container } from "./Sign-up.styles";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import userApi from "../../api/user";

const SignUpAm = ({ handeleToggle }) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [userName, setuserName] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState("male");
	const [address, setAddress] = useState("");
	const [birthDate, setBirthDate] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPasswor] = useState("");
	const [picture, setPicture] = useState("");
	const [match, setMatch] = useState("");
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (password === confirmPassword && password.length > 1) {
			setMatch(false);
		} else if (
			password !== confirmPassword &&
			password.length > 1 &&
			confirmPassword.length > 1
		) {
			setMatch(true);
		}
	}, [password, confirmPassword]);
	const navigate = useNavigate();
	const handleSubmit = (e) => {
		console.log("CLICKED");
		e.preventDefault();
		if (!checked) {
			return toast.error("Please agree to the terms and conditions");
		}
		let data = {
			firstName: firstName,
			lastName: lastName,
			userName: userName,
			phoneNumber: phone,
			email: email,
			password: password,
			gender: gender,
			address: address,
			birthDate: birthDate,
		};

		password === confirmPassword && password.length > 2
			? toast.promise(userApi.register({ ...data }, onSuccess, onFaileure), {
					pending: "proccessing ...",
			  })
			: toast.error("password didn't match");
	};

	const onSuccess = () => {
		handeleToggle();
		setTimeout(() => {
			navigate("/sign-in");
		}, 1000);
	};
	const onFaileure = (error) => {
		console.log(error);
	};

	const handleImage = (e) => {
		setPicture(e);
	};
	return (
		<Container>
			<ToastContainer />
			<form
				className='two-by-two top-100'
				// onSubmit={handleSubmit}
				method='post'
			>
				<Col>
					<h4 className='title '>ይመዝገቡ</h4>

					<div className='two-by-two'>
						<FormInput
							placeholder='
							የመጀመሪያ ስም'
							name='firstName'
							required
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<FormInput
							placeholder='
							ያባት ስም'
							name='lastName'
							required
							onChange={(e) => setLastName(e.target.value)}
						/>
						<FormSelect onChange={(e) => setGender(e.target.value)}>
							<option value='first' disabled>
								ጾታ
							</option>
							<option value='male'>ወንድ</option>
							<option value='female'>ሴት</option>
						</FormSelect>

						<FormInput
							placeholder='ኢሜይል'
							type='email'
							name='email'
							required
							onChange={(e) => setEmail(e.target.value)}
						/>

						<PhoneInput
							className='form-control'
							placeholder='ስልክ ቁጥር አስገባ'
							value={phone}
							onChange={setPhone}
						/>
						<FormInput
							placeholder='አድራሻ'
							type='text'
							name='address'
							required
							onChange={(e) => setAddress(e.target.value)}
						/>

						<FormInput
							placeholder='የተጠቃሚ ስም
							'
							type='text'
							name='userName'
							required
							onChange={(e) => setuserName(e.target.value)}
						/>
						<FormInput
							placeholder='date'
							type='date'
							name='BirthDate'
							required
							onChange={(e) => setBirthDate(e.target.value)}
						/>
					</div>

					<FormInput
						onChange={(event) => {
							setPassword(event.target.value);
						}}
						placeholder=' 
						የይለፍ ቃል'
						invalid={match}
						valid={match === false}
						className='mt-3'
						type='password'
						required
						name='password'
					/>

					<FormInput
						className='mt-3'
						type='password'
						required
						name='confirmPassword'
						onChange={(event) => {
							setConfirmPasswor(event.target.value);
						}}
						placeholder='የይለፍ ቃል አረጋግጥ'
						invalid={match}
						valid={match === false}
					/>
					<div className='two-by-two desktop'>
						<FormCheckbox
							onChange={() => {
								setChecked(!checked);
							}}
							checked={checked}
						>
							{" "}
							I have read and agree to{" "}
							<Link
								to='/sign-up'
								style={{
									color: "#25E5AB",
									fontWeight: "bold",
								}}
							>
								አተገባበሩና ​​መመሪያው
							</Link>
						</FormCheckbox>

						<p style={{ margin: "0px auto" }}>
							ተመዝግበዋል?{" "}
							<Link
								to='/sign-inam'
								style={{ color: "#25E5AB", fontWeight: "bold" }}
							>
								ግባ
							</Link>
						</p>
					</div>
				</Col>
				<Col>
					{picture ? (
						<img src={picture} alt='contact' className='contact' />
					) : (
						<img
							src={require("../../Assets/contact.png")}
							alt='contact'
							className='contact'
						/>
					)}
					<CustomFileUpload onChange={handleImage} />
				</Col>
			</form>
			<div className='two-by-two mobile'>
				<FormCheckbox
					onChange={() => {
						setChecked(!checked);
					}}
					checked={checked}
				>
					<Link
						to='/sign-upa,'
						style={{
							color: "#25E5AB",
							fontWeight: "bold",
						}}
					>
						አተገባበሩና ​​መመሪያውን
					</Link>
					አንብቤ ተስማምቻለሁ
				</FormCheckbox>

				<p style={{ margin: "0px auto" }}>
					ተመዝግበዋል?{" "}
					<Link to='/sign-in' style={{ color: "#25E5AB", fontWeight: "bold" }}>
						ግባ
					</Link>
				</p>
			</div>
			<div className='two-by-two'>
				<Button
					theme='success'
					className='submmit'
					type='submit'
					onClick={handleSubmit}
				>
					ይመዝገቡ
				</Button>
			</div>
		</Container>
	);
};

export default SignUpAm;
