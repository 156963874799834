import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "doctors",
  initialState: [],
  reducers: {
    doctorsAdded: (doctors, action) => {
      doctors.length === 0 && action.payload.map((doc) => doctors.push(doc));
    },
  },
});

export const { doctorsAdded } = slice.actions;

export default slice.reducer;
