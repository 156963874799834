import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";

import { Button, Col, FormInput } from "shards-react";

import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router";
import userApi from "../../api/user";
import CustomFileUpload from "../../components/Custom-file-upload/custom-file-upload";
import Featching from "../../components/Featching/loading.page";
import { userSignedIn } from "../../store/user-reducer";
import { removeKey } from "../../utils/deleteKeys";
import { Container } from "./Edit-profile.styles";

const EditProfileAm = () => {
	const user = useSelector((state) => state.user[0]);
	const [tempUser, setTempUser] = useState({
		firstName: "",
		lastName: "",
		userName: "",
		email: "",
		phoneNumber: "",
	});
	const [fetch, setFetch] = useState(false);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		setLoading(true);
		let id = sessionStorage.getItem("id");
		id &&
			userApi.getOne(
				id,
				(resp) => {
					dispatch(userSignedIn(resp.data.patient));
					setTempUser(resp.data.patient);
					setLoading(!loading);
				},
				(error) => {
					console.log("this went off", error);
					setLoading(!loading);
				}
			);
		// eslint-disable-next-line
	}, [fetch]);

	useEffect(() => {
		if (!user && !loading) {
			navigate("/");
		}
		// eslint-disable-next-line
	}, [loading]);

	let handleSubmit = () => {
		toast.promise(
			userApi.update(removeKey(tempUser), tempUser._id, onSuccess, onFaileure),
			{
				pending: "proccessing ...",
			}
		);
	};

	const onSuccess = () => {
		setFetch(!fetch);
	};
	const onFaileure = (error) => {
		console.log(error);
		setFetch(!fetch);
	};

	const handleChange = (event) => {
		setTempUser({ ...tempUser, [event.target.name]: event.target.value });
	};
	const handleImage = (e) => {
		setTempUser({ ...tempUser, profilePicture: e });
	};

	const setPhone = (e) => {
		setTempUser({ ...tempUser, phoneNumber: e });
	};
	return (
		<Container>
			<h4 className='title '>Update Profile</h4>
			<ToastContainer />
			{!user && loading ? (
				<Featching />
			) : (
				<div className='update  top-100'>
					<Col>
						{tempUser.profilePicture ? (
							<img
								src={tempUser.profilePicture}
								alt='contact'
								className='contact'
							/>
						) : (
							<img
								src={require("../../Assets/contact.png")}
								alt='contact'
								className='contact'
							/>
						)}
						<CustomFileUpload onChange={handleImage} />
					</Col>
					<Col>
						<div className='two-by-two'>
							<FormInput
								placeholder='First Name'
								name='firstName'
								value={tempUser.firstName}
								onChange={handleChange}
							/>
							<FormInput
								placeholder='Last Name'
								name='lastName'
								value={tempUser.lastName}
								onChange={handleChange}
							/>
							<FormInput
								placeholder='Username'
								name='userName'
								value={tempUser.userName}
								onChange={handleChange}
							/>
							<FormInput
								placeholder='Email'
								type='email'
								name='email'
								value={tempUser.email}
								onChange={handleChange}
							/>
							<PhoneInput
								className='form-control'
								placeholder='Enter phone number'
								value={tempUser.phoneNumber}
								onChange={setPhone}
							/>
						</div>

						<Button theme='success' className='submmit' onClick={handleSubmit}>
							Save changes
						</Button>
					</Col>
				</div>
			)}
		</Container>
	);
};

export default EditProfileAm;
