import styled from "styled-components";

export const Container = styled.div`
	display: none;

	.navbar {
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: center;
		background-color: #14558d;
		color: white;
	}

	.brand-title {
		font-size: 1.5rem;
		margin: 0.5rem;
	}

	.navbar-links {
		height: 100%;
	}

	.navbar-links ul {
		display: flex;
		margin: 0;
		padding: 0;
	}

	.navbar-links {
		list-style: none;
	}

	.navbar-links a {
		display: block;
		text-decoration: none;
		color: white;
		padding: 1rem;
	}

	.navbar-links a:hover {
		background-color: #14558d;
	}
	.logo {
		height: 70px;
	}

	.toggle-button {
		position: absolute;
		top: 32px;
		right: 30px;
		display: none;
		flex-direction: column;
		justify-content: space-between;
		width: 30px;
		height: 21px;
		background-color: transparent;
		border: none;
		color: #fff;
		font-size: 20px;
	}

	.toggle-button .bar {
		height: 3px;
		width: 100%;
		background-color: white;
		border-radius: 10px;
	}
	.menuss {
		padding-top: 10px;
	}

	@media (max-width: 600px) {
		display: block;
		.logo {
			height: 50px;
		}
		.navbar {
			flex-direction: column;
			align-items: flex-start;
		}

		.toggle-button {
			display: flex;
		}

		.navbar-links {
			display: none;
			width: 100%;
		}

		.navbar-links ul {
			width: 100%;
			flex-direction: column;
		}

		.navbar-links ul {
			text-align: center;
		}

		.navbar-links ul a {
			padding: 0.5rem 1rem;
		}

		.navbar-links.active {
			display: flex;
		}
	}

	/* Background Styles Only */

	html {
		background-color: #dfdfdf;
	}

	.side-links {
		position: absolute;
		bottom: 15px;
		right: 15px;
	}

	.side-link {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		margin-bottom: 10px;
		color: white;
		width: 180px;
		padding: 10px 0;
		border-radius: 10px;
	}

	.side-link-youtube {
		background-color: red;
	}

	.side-link-twitter {
		background-color: #1da1f2;
	}

	.side-link-github {
		background-color: #14558d;
	}

	.side-link-text {
		margin-left: 10px;
		font-size: 18px;
	}

	.side-link-icon {
		color: white;
		font-size: 30px;
	}
`;
