import styled from "styled-components";

export const Container = styled.div`
	width: 100%;

	padding: 50px;
	min-height: calc(100vh - 100px - 220px);
	.two-by-two {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;
		padding: 0;
		padding-left: 380px;
		padding-right: 50px;
		margin: 0;
		/* width: 80%; */
	}
	button {
		margin-bottom: 50px;

		width: 300px;
	}
	p {
		margin-bottom: 15px;
	}
	.doc-list {
		height: fit-content;
		padding: 30px;
		display: grid;
		grid-template-columns: 300px 1fr;
		grid-gap: 50px;

		.doc-img {
			height: 100%;
			max-height: 45vh;
			width: 100%;
			object-fit: cover;
		}
	}

	h4 {
		padding: 10px 10px;
		text-align: center;
	}
	hr {
		padding: 0;
		margin: 0;
	}
	.flex {
		display: flex;
	}
	@media screen and (max-width: 600px) {
		overflow-x: hidden;
		padding: 20px;
		button {
			transform: translateX(-105%);

			width: 250px;
		}
		.doc-list {
			display: flex;
			flex-direction: column;
		}
		.book-botton {
			margin-left: 0px;
		}
		.two-by-two {
			grid-template-columns: 1fr;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
`;
