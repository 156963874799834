import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "shards-ui/dist/css/shards.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";

import { Provider } from "react-redux";
import "./index.css";
import configureStore from "./store/store";
const root = ReactDOM.createRoot(document.getElementById("root"));

const store = configureStore();

root.render(
	<div>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	</div>
);
