import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import userApi from "../../api/user";
import Loading from "../../components/loading/loading.page";

const Activate = () => {
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		userApi.activate(
			{
				activation_token: params.id,
			},
			onSuccess,
			onFailure
		);

		// eslint-disable-next-line
	}, []);

	const onFailure = (error) => {
		toast.error("Something went wrong plase contact the admin  ");
		setTimeout(() => {
			navigate("/");
		}, 5000);
	};

	const onSuccess = () => {
		//
		setTimeout(() => {
			navigate("/sign-in");
		}, 3000);
	};

	return (
		<div>
			<ToastContainer />
			<Loading />
		</div>
	);
};

export default Activate;
