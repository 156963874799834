import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import userApi from "../../api/user";
import { userSignedIn, userSignedOut } from "../../store/user-reducer";
import { Container } from "./mobile.styles";
const MobileNav = () => {
	const user = useSelector((state) => state.user[0]);
	const [open, setOpen] = useState(false);
	const toggle = () => {
		setOpen(!open);
	};

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const location = useLocation();

	const onLogout = () => {
		dispatch(userSignedOut([]));
		sessionStorage.removeItem("token");
		sessionStorage.removeItem("id");
		sessionStorage.clear();
		navigate("/");
	};

	useEffect(() => {
		let id = sessionStorage.getItem("id");
		id &&
			userApi.getOne(
				id,
				(resp) => dispatch(userSignedIn(resp.data.patient)),
				(error) => console.log("this went off", error)
			);
		// eslint-disable-next-line
	}, []);
	const changeLanguage = () => {
		if (location.pathname.slice(-2) !== "am") {
			if (location.pathname === "/") {
				navigate(`/am`);
			} else navigate(`${location.pathname}/am`);
		} else {
			navigate(location.pathname.slice(0, -3));
		}
	};
	return (
		<Container>
			<nav className='navbar'>
				<Link to='/'>
					<img
						src={require("../../Assets/logo.png")}
						alt='logo'
						className='logo'
					/>
				</Link>
				{!open && (
					<p className='toggle-button' onClick={toggle}>
						<span className='bar'></span>
						<span className='bar'></span>
						<span className='bar'></span>
					</p>
				)}
				{open && (
					<button className='toggle-button' onClick={toggle}>
						<AiOutlineClose />
					</button>
				)}
				<div
					className={` menuss ${open ? "navbar-links active" : "navbar-links"}`}
				>
					<ul>
						<Link to='/' onClick={toggle}>
							HOME
						</Link>

						<Link to='/appointments' onClick={toggle}>
							Appointments
						</Link>
						<Link to='/doctors' onClick={toggle}>
							Doctors
						</Link>
						{user && (
							<Link to='/edit-profile' onClick={toggle}>
								Edit Profile
							</Link>
						)}
						{user && <Link onClick={onLogout}>Logout</Link>}
						{!user &&
							(location.pathname === "/sign-in" ? (
								<Link to='/sign-up' onClick={toggle}>
									Sign-up
								</Link>
							) : (
								<Link to='/sign-in' onClick={toggle}>
									Sign-In
								</Link>
							))}
						<button
							onClick={changeLanguage}
							style={{
								border: "none",
								backgroundColor: "transparent",
								color: "#ffffff",
							}}
						>
							{location.pathname.slice(-2) !== "am" ? "አማ" : "En"}
						</button>
					</ul>
				</div>
			</nav>
		</Container>
	);
};
export default MobileNav;
