export const formatDepartment = (departments, amharic) => {
	let temp = [];

	departments.map((dept) =>
		temp.push({
			label: amharic
				? dept.deptName.am.charAt(0).toUpperCase() + dept.deptName.am.slice(1)
				: dept.deptName.en.charAt(0).toUpperCase() + dept.deptName.en.slice(1),
			value: dept._id,
			name: "department",
		})
	);

	return temp;
};

export const formatLanguage = (language) => {
	let temp = [];

	language.map((lang) =>
		temp.push({
			label: lang.title.charAt(0).toUpperCase() + lang.title.slice(1),
			name: "language",
			value: lang._id,
		})
	);

	return temp;
};

export const formatLocation = (locations) => {
	let temp = [];

	locations.map((loc) =>
		temp.push({
			label: loc.city.charAt(0).toUpperCase() + loc.city.slice(1),
			value: loc._id,
			name: "location",
		})
	);

	return temp;
};
