import styled from "styled-components";

export const Container = styled.div`
	width: 100vw;
	background-color: #f8f9fa;
	height: fit-content;
	padding: 50px 100px;
	margin-top: 100px;
	.long-text {
		overflow: hidden;
		white-space: nowrap; /* Don't forget this one */
		text-overflow: ellipsis;
	}
	h1 {
		margin-top: 0 !important;
		font-size: 32px;
	}
	.docs-container {
		margin-top: 50px;
		padding: 10px;
	}
	.doc {
		max-width: 100%;
		overflow: hidden;
		box-shadow: none;
		height: fit-content !important;
		padding-bottom: 0 !important;
		img {
			max-height: 250px;
			height: 250px;
			object-fit: cover;
		}
	}
	.dot {
		width: 10px;
		height: 10px !important;
		font-size: 10px;
		border-radius: 50px;
		background-color: green;
		color: transparent;
		margin-top: 8px;
	}
	.doc-img {
		width: 100%;
		height: 50%;
		max-height: 45vh;
		object-fit: cover;
	}
	.desc-container {
		padding: 20px 10px;
		display: flex;
		flex-direction: column;
	}
	@media screen and (max-width: 670px) {
		padding: 50px 20px;
		.docs-container {
			grid-template-columns: 1fr;
		}
	}
`;
