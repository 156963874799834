import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "search",
	initialState: {
		department: "",
		language: "",
		gender: "",
		location: "",
	},
	reducers: {
		searchParamsChanged: (search, action) => {
			search.gender = action.payload.gender;
			search.department = action.payload.department;
			search.language = action.payload.language;
			search.location = action.payload.location;
		},
	},
});

export const { searchParamsChanged } = slice.actions;

export default slice.reducer;
