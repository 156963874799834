import styled from "styled-components";

export const Container = styled.div`
	width: 100vw;
	height: fit-content;
	margin-top: 100px;
	background-color: #15558d;
	color: #fff;
	padding: 20px 5%;
	.top {
		display: flex;
		justify-content: space-between;
	}
	.logo__footer {
		height: 70px;
		width: auto;
		object-fit: cover;
		margin-top: 20px;
	}
	span {
		width: 100vw;
		text-align: center;
		border: 2px solid red;
	}
	a {
		color: #fff;
	}
	p {
		font-size: 15px;
		text-align: center;
		padding-top: 17px;

		font-weight svg {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 460px) {
		height: fit-content;
		padding: 20px 10%;
		padding-bottom: 30px !important;
		text-align: center !important;
		img {
			width: 300px;
			margin: 0 auto;
		}
		.top {
			flex-direction: column;
		}
	}
`;
