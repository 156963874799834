import React from "react";
import { useLocation } from "react-router";
import Slider from "react-slick";
import { Container, Rounded } from "./slider.styles";

export default function CustomSlider({ title }) {
	const location = useLocation();
	let data = [
		{
			url: "bone.png",
			name: location.pathname.slice(-2) === "am" ? "ኦርቶፔዲክ" : "Orthopedic ",
		},
		{
			url: "heart-trans.png",
			name: location.pathname.slice(-2) === "am" ? "የልብ ሐኪሞች" : "Cardiologists",
		},

		{
			url: "brain.png",
			name: location.pathname.slice(-2) === "am" ? "ኒውሮሎጂ" : "Neurology",
		},
		{
			url: "tooths.png",
			name: location.pathname.slice(-2) === "am" ? "የጥርስ ሐኪም" : "Dentist",
		},

		{
			url: "kidny-small.png",
			name: location.pathname.slice(-2) === "am" ? "ኔፍሮሎጂስቶች" : "Nephrologists",
		},
		{
			url: "bone.png",
			name: location.pathname.slice(-2) === "am" ? "ኦርቶፔዲክ" : "Orthopedic ",
		},
		{
			url: "heart-trans.png",
			name: location.pathname.slice(-2) === "am" ? "የልብ ሐኪሞች" : "Cardiologists",
		},

		{
			url: "brain.png",
			name: location.pathname.slice(-2) === "am" ? "ኒውሮሎጂ" : "Neurology",
		},
		{
			url: "tooths.png",
			name: location.pathname.slice(-2) === "am" ? "የጥርስ ሐኪም" : "Dentist",
		},

		{
			url: "kidny-small.png",
			name: location.pathname.slice(-2) === "am" ? "ኔፍሮሎጂስቶች" : "Nephrologists",
		},
	];

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		initialSlide: 0,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<Container>
			<Slider {...settings} className='card__container--inner'>
				{data.map((item, index) => {
					return (
						<div key={index}>
							<Rounded>
								<img src={require(`../../Assets/${item.url}`)} alt='' />
							</Rounded>
							<h5>{item.name}</h5>
						</div>
					);
				})}
			</Slider>
		</Container>
	);
}
