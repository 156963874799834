import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	overflow-x: hidden;
	padding: 50px;
	.filter {
		height: fit-content;
		width: 90%;
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		padding-bottom: 30px;
	}
	.doc-list {
		height: fit-content;
		padding: 30px;
		display: grid;
		grid-template-columns: 200px 1fr 300px;
		grid-gap: 20px;
		overflow: hidden;
	}
	p {
		margin-bottom: 10px;
	}
	h4 {
		padding: 10px 10px;
		text-align: center;
	}
	hr {
		padding: 0;
		margin: 0;
	}
	.doc-img {
		height: auto;
		max-height: 45vh;
		width: 100%;
		object-fit: cover;
	}

	.book-botton {
		background-color: #0de0fe;
		border: none;
		height: 50px;
		margin-left: 100%;
		transform: translateX(-100%);
		width: 170px;
	}
	@media screen and (max-width: 600px) {
		padding: 15px;
		.filter {
			width: 100%;
		}
		.doc-list {
			display: flex;
			flex-direction: column;
		}
		.book-botton {
			margin-left: 0px;
		}
		.book-botton {
			background-color: #0de0fe;
			border: none;
			height: 50px;
			margin-left: 0%;
			transform: translateX(0%);
			width: 170px;
		}
	}
`;
