import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	overflow-x: hidden;
	padding: 50px;

	.two-by-two {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 100px;
		max-height: 100vh;

		height: 70vh !important;
	}
	.login-vector {
		max-width: 100%;
		margin-top: 50%;
		transform: translateY(-50%);
	}
	.form {
		margin-top: 50%;
		transform: translateY(-50%);
	}
	.form-card {
		padding: 50px;
	}
	.height-50 {
		max-width: 100%;
		margin: 0 auto;
	}
	h2 {
		margin-bottom: 50px;
	}
	@media screen and (max-width: 600px) {
		padding-top: 10px;
		height: fit-content !important;
		padding: 10px 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.two-by-two {
			grid-template-columns: 1fr;
			max-height: 50vh !important;
			margin-top: 50px;

			padding: 0;
		}
		.login-vector {
			display: none;
		}
	}
`;
