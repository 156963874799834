import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	Card,
	FormInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "shards-react";
import login from "../../Assets/images/undraw.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import userApi from "../../api/user";
import { Container } from "./forgot.styles";

const ForgotPassword = ({ setReset }) => {
	const [email, setEmail] = useState("");
	const navigate = useNavigate();
	const handleReset = () => {
		toast.promise(
			userApi.forgetPassword({ email: email }, onSuccess, onFaileure),
			{
				pending: "Proccessing ...",
			}
		);
	};

	const onFaileure = (error) => {
		console.log(error);
	};

	const onSuccess = (data) => {
		setReset(true);
		setTimeout(() => {
			navigate("/sign-in");
		}, 5000);
		console.log(data);
	};

	return (
		<Container>
			<ToastContainer />

			<div className='two-by-two'>
				<img src={login} className='login-vector' alt='home vector' />
				<div className='form'>
					<Card className='form-card'>
						<Row>
							<h2 style={{ marginBottom: "30px" }}>Forgot Your password ?</h2>
						</Row>
						<Row>
							<p style={{ marginBottom: "30px" }}>Find Your account</p>
						</Row>
						<Row>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>mail</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									placeholder='Email'
								/>
							</InputGroup>
						</Row>

						<Row style={{ margin: "10px auto" }}>
							<Button
								className='login-button'
								theme='success'
								onClick={handleReset}
							>
								Reset My Password
							</Button>
						</Row>
						<Row
							style={{
								display: "flex!important",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<span></span>
							<Link style={{ float: "right" }} to='/'>
								Login
							</Link>
						</Row>
					</Card>
				</div>
			</div>
		</Container>
	);
};

export default ForgotPassword;
