import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	Button,
	Card,
	Form,
	FormInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "shards-react";
import login from "../../Assets/login.png";
import { Container } from "./Login.styles";

import { useDispatch } from "react-redux";

import { userSignedIn } from "../../store/user-reducer";

import { toast, ToastContainer } from "react-toastify";

import { useNavigate } from "react-router-dom";
import userApi from "../../api/user";

const LogIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = (e) => {
		e.preventDefault();
		toast.promise(
			userApi.login(
				{ password: password, email: email },
				onSuccess,
				onFaileure
			),
			{
				pending: "Proccessing ...",
			}
		);
	};

	const onSuccess = (data) => {
		userApi.getRefreshToken(
			{
				refresh_token: data.data.refresh_token,
			},
			refreshTokenSuccess,
			onFaileure
		);
	};
	const refreshTokenSuccess = (res) => {
		sessionStorage.setItem("token", res.data.access_token);
		sessionStorage.setItem("id", res.data.user[0]._id);
		dispatch(userSignedIn(res.data.user[0]));

		setTimeout(() => {
			navigate("/");
		}, 500);
	};
	const onFaileure = (error) => {
		console.log(error);
	};

	return (
		<Container>
			<ToastContainer />
			<div className='two-by-two'>
				<img src={login} className='login-vector' alt='home vector' />

				<Form onSubmit={handleLogin} method='post'>
					<Card className='form-card'>
						<Row>
							<h2>Login</h2>
						</Row>
						<Row style={{ marginBottom: "20px" }}>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>mail</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									required
									name='email'
									placeholder='Email / Username'
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</InputGroup>
						</Row>
						<Row style={{ marginBottom: "20px" }}>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>password</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									required
									name='password'
									type='password'
									placeholder='Password'
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
							</InputGroup>
						</Row>
						<Row
							style={{
								display: "flex!important",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<span></span>
							<Link style={{ float: "right" }} to='/user/forgot-password'>
								Forgot Password?
							</Link>
						</Row>
						<Row style={{ margin: "10px auto" }}>
							<Button theme='success' type='submit'>
								Login
							</Button>
						</Row>
						<Row>
							<p style={{ margin: "7px auto" }}>
								Don’t have an account?{" "}
								<Link
									to='/sign-up'
									style={{ color: "#25E5AB", fontWeight: "bold" }}
								>
									{" "}
									Register
								</Link>
							</p>
						</Row>
					</Card>
				</Form>
			</div>
		</Container>
	);
};

export default LogIn;
