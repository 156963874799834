import { useEffect, useState } from "react";
import { BsClockFill, BsFillCalendarDateFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button, Card, Col, FormSelect, Row } from "shards-react";
import { Container } from "./Appointments.styles";

import { Link, useNavigate } from "react-router-dom";
import appointmentApi from "../../api/appointment";
import Featching from "../../components/Featching/loading.page";
const Appointments = () => {
	const user = useSelector((state) => state.user[0]);
	const doctors = useSelector((state) => state.doctors);
	const [fetch, setFetch] = useState(false);

	const [loading, setLoading] = useState(true);
	const [appointments, setAppointments] = useState([]);
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		setLoading(true);
		appointmentApi.getByPatient(user && user._id, fetchSuccess, onFaliure);
	}, [user, doctors, fetch]);

	const fetchSuccess = (res) => {
		setLoading(false);
		setData(res.data.appointments);
		setAppointments(res.data.appointments);
	};
	const onFaliure = (error) => {
		setLoading(false);

		console.log(error);
	};

	const handleDelete = (id) => {
		appointmentApi.deleteAppointment(id, deleteSuccess, onFaliure);
	};

	const deleteSuccess = (res) => {
		setFetch(!fetch);
	};

	const onSearch = () => {
		let filterd = data;

		if (filter === "today") {
			filterd = filterd.filter(
				(appt) => new Date(appt.appointmentDate) === new Date()
			);
		}

		if (filter === "all") {
			filterd = data;
		}
		if (filter === "past") {
			filterd = filterd.filter(
				(appt) => new Date(appt.appointmentDate) < new Date()
			);
		}
		if (filter === "upcoming") {
			filterd = filterd.filter(
				(appt) => new Date(appt.appointmentDate) > new Date()
			);
		}
		setAppointments(filterd);
	};

	return (
		<Container>
			<ToastContainer />

			<Row>
				<Col lg={3} md={12}>
					<Card className='form-card filter'>
						<h4>Filter</h4>
						<hr />

						<FormSelect
							className='mt-4'
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						>
							<option value=''>Date</option>
							<option value='all'>All</option>
							<option value='past'>Past</option>
							<option value='today'>Today</option>
							<option value='upcoming'>Upcoming</option>
						</FormSelect>

						<Button theme='success' className='mt-3' onClick={onSearch}>
							Search
						</Button>
						<Button
							theme='success'
							className='mt-3'
							onClick={() => {
								setAppointments(data);
							}}
						>
							Clear
						</Button>
					</Card>
				</Col>
				<Col lg={9} md={12}>
					{sessionStorage.getItem("id") && loading && <Featching />}
					{!sessionStorage.getItem("id") && !loading && (
						<p
							style={{
								width: "50%",
								textAlign: "center",
								margin: "100px auto",
								fontSize: "22px",
							}}
						>
							Please <Link to='/sign-in'>Sign in</Link> to view your
							appointments
						</p>
					)}
					{!appointments.length &&
						user &&
						(loading ? (
							<Featching />
						) : (
							<p
								style={{
									width: "50%",
									textAlign: "center",
									margin: "100px auto",
									fontSize: "22px",
								}}
							>
								You Have No Appointments
							</p>
						))}
					{appointments.map((appt, index) => (
						<Card
							className={`form-card doc-list ${index !== 0 && "mt-5"}`}
							key={index}
						>
							<img
								src={
									doctors.find((doc) => doc._id === appt.doctor)
										? doctors.find((doc) => doc._id === appt.doctor)
												.profilePicture
										: ""
								}
								alt=''
								className='doc-img'
							/>
							<div>
								<h5>
									{doctors.find((doc) => doc._id === appt.doctor) &&
										doctors.find((doc) => doc._id === appt.doctor).firstName
											.en}{" "}
									{doctors.find((doc) => doc._id === appt.doctor) &&
										doctors.find((doc) => doc._id === appt.doctor).lastName.en}
								</h5>
								<p>
									{doctors.find((doc) => doc._id === appt.doctor) &&
										doctors.find((doc) => doc._id === appt.doctor)
											.specialization}
								</p>
							</div>
							<div className='info'>
								<p>
									<BsFillCalendarDateFill />{" "}
									{new Date(appt.appointmentDate).toISOString().slice(0, 10)}
								</p>
								<p>
									<BsClockFill />{" "}
									{appt.appointmentTime && appt.appointmentTime.hour} :{" "}
									{appt.appointmentTime && appt.appointmentTime.minute < 10
										? `0${appt.appointmentTime.minute}`
										: appt.appointmentTime && appt.appointmentTime.minute}
								</p>
								<p>Reason : {appt.reasonForVisit}</p>
							</div>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "1fr",
									gridGap: "10px",
								}}
							>
								<Button
									theme='success'
									style={{ height: "50px" }}
									onClick={() =>
										navigate(
											`/reschedule-appointment/${appt._id}/${appt.doctor}`
										)
									}
								>
									Reschedule
								</Button>
								<Button
									theme='danger'
									style={{ height: "50px" }}
									onClick={() => handleDelete(appt._id)}
								>
									Cancel
								</Button>
							</div>
						</Card>
					))}
				</Col>
			</Row>
		</Container>
	);
};

export default Appointments;
